<template>
  <div class="manage-amenities animatedBox">
    <div class="container fluid">
      <div class="card">
        <!-- Table -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="amenitiesTableColumns"
          :rows="amenitiesTableData"
          :totalRows="amenitiesTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openPropertyDetail"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddAmenity">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn mr-1" @click="toEditAmenity(props.row.id)">
                <i class="fas fa-pen"></i>
              </button>
              <!-- TODO: Wait for delete API -->
              <button class="btn danger" @click="deleteAmenities(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-amenities",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      amenitiesTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      amenitiesTableData: [],
      amenitiesTablePagination: {},
      isFilterActive: false,
      filter: {
        referenceCode: "",
        "title[partial]": "",
        category: "",
        furnishingType: "",
        purpose: ""
      }
    };
  },
  mounted() {
    this.getAllAmenities({ limit: this.tableParams.perPage });
  },
  methods: {
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllAmenities();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllAmenities();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddAmenity() {
      this.$router.push({ name: "ManageAmenitiesAdd" });
    },
    toEditAmenity(id) {
      this.$router.push({ name: "ManageAmenitiesEdit", params: { id: id } });
    },
    openPropertyDetail(event) {
      /* this.isPropertyDetailShown = true;
      this.isPropertyDetailLoading = true;
      this.getPropertyDetail(event.row.id).then(() => {
        this.isPropertyDetailLoading = false;
      }); */
    },

    // ============================= API Related ===============================
    async getAllAmenities(queries = {}) {
      this.$store.commit("setIsLoading", true);
      let vm = this;
      let data = await this.$store.dispatch("manageAmenities/getAllAmenities", {
        ...queries,
        page: this.tableParams.page,
        perPage: this.tableParams.perPage
      });

      this.amenitiesTableData = this._.cloneDeep(data.data);
      this.amenitiesTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    async deleteAmenities(id) {
      const c = confirm("Are you sure to delete?");

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          let data = await this.$store.dispatch(
            "manageAmenities/deleteAmenities",
            id
          );

          this.$store.commit("setIsLoading", false);
          this.getAllAmenities();
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          this.$store.commit("setIsLoading", false);
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-amenities {
}
</style>
